.pure-modal .pure-backdroup {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #00000033;
  backdrop-filter: blur(1px);
  z-index: 1000;
  display: flex;
  align-items: flex-end;
  animation: modalFade 1s forwards;
}

.pure-modal .content {
  position: fixed;
  top: 5vh;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100000;
  background-color: #fff;
 
  width: 100%;
  min-height: 150px;
  border-radius: 16px 16px 0px 0px;
  transform: translateY(100%);
  transition: .4s;
}
.pure-modal .open-modal {
  animation: contentFade 0.4s forwards;
  animation-timing-function: ease-out;
} 
/* 
 .pure-modal .close-modal {
  transform: translateY(100%);
  animation: contentClose 0.2s ;
  animation-timing-function: ease-out;
} */

@keyframes modalFade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* @keyframes contentClose {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
} */

@keyframes contentFade {
  0% {
    transform: translateY(100%);
  }

  60% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(0px);
  }
}

