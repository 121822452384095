.prediction-screen {
  flex: 1;
}

.prediction-screen .header {
  height: 220px;
  position: relative;
  background-image: url("../../assets//images/cover.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.prediction-screen .header .overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(98, 7, 34, 0.7) 39.06%,
    rgba(0, 0, 81, 0.42) 100%
  );
}

.prediction-screen .header .overlay .header-info {
  margin-top: 30px;
  display: flex;
  align-items: flex-start;
  color: #fff;
}
.prediction-screen .header .overlay .header-info .info {
  margin-right: 10px;
  margin-top: 4px;
}
.prediction-screen .header .overlay .header-info b {
  font-size: 20px;
}
.prediction-screen .header .overlay .header-info p {
  font-size: 14px;
  margin-top: 4px;
}

.prediction-screen .content {
  min-height: 100px;
  background-color: #fff;
  border-radius: 16px 16px 0 0;
  width: 100%;
  margin-top: -20px;
  position: relative;
}

.prediction-screen .content .match {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.prediction-screen .content .match .team {
  text-align: center;
  margin-top: -36px;
}

.prediction-screen .content .match .team p {
  margin-top: 4px;
  font-size: 18px;
}

.prediction-screen .content .match .team img {
  width: 72px;
  height: 72px;

  border: 1px solid #f0f0f0;
  border-radius: 8px;
}
.prediction-screen .content .match span {
  opacity: 0;
}

.prediction-screen .content .form {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.prediction-screen .content .form input {
  background: #ffffff;
  border: none;
  border: 1px solid #f0f0f0;
  border-radius: 16px;
  font-size: 20px;
  height: 56px;
  width: 56px;
  text-align: center;
  transition: 0.2s;
}

.prediction-screen .content .form input:focus {
  outline-color: #0000ff;
}

.prediction-screen .content .form {
  font-size: 40px;
  margin-top: 26px;
}

.prediction-screen .send-btn {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1), 0px -1px 0px #f2f2f2;
  padding-top: 10px;
  padding-bottom: 20px;
}
