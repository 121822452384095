.app-screen {
  overflow: hidden;
  position: fixed;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
