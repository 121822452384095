.ui-btn {
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  background-color: transparent;
  border: 1px solid #0000ff;
  color: #0000ff;
}

.ui-btn svg {
  margin-right: 6px;
}

.ui-btn-primary{
    background-color: #0000ff;
    color: #fff;
}

.ui-btn:disabled{
  background-color: #f0f0f0;
  color: #ccc;
  border-color: #ccc;
}
