.app-slider {
  position: relative;
 
}

/* .app-slider .app-container{
  margin-bottom: 6px;
} */


.app-slider .swip-dots{
    display: flex;
    /* padding-top: 10px; */
    text-align: center;
    justify-content: center;
    width: 100%;
}

.app-slider .swip-dots .dot{
    width: 24px;
    height: 4px;
    margin: 0px 3px;
    background-color: #F0F0F0;
    border-radius: 2px;
    transition: .3s;
}
.app-slider .swip-dots .dot-active{
    background-color: #CCCCCC;
}

