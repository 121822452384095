.scor-card {
  width: -webkit-fill-available;
  border-radius: 16px;
  border: 1px solid #f0f0f0;
  padding: 12px;
}

.scor-card b {
  font-size: 18px;
  color: #0000ff;
  font-weight: bold;
}

.scor-card .scor-info {
  padding: 8px;
  border-radius: 8px;
  background-color: #0000ff;
  display: flex;
  justify-content: space-around;
  height: 80px;
  align-items: center;
  position: relative;

}
.scor-card .scor-info .scor-item {
  text-align: center;
}

.scor-card .scor-info span.divider {
  display: block;
  height: 50%;
  width: 1px;
  background-color: #fff;
  opacity: 0.6;
}

.scor-card .scor-info .scor-item svg {
  height: 26px;
}

.scor-card .scor-info .scor-item p {
  color: #fff;
  font-size: 14px;
  opacity: 0.6;
  margin-bottom: 8px;
}

.scor-card .scor-info .scor-item b {
  color: #fff;
  font-size: 16px;
}
