.home-skeleton .banner {
  margin-top: 20px;
  height: 140px;
  border-radius: 16px;
}
.home-skeleton .banner-dot {
  display: flex;
  width: 110px;
  margin: auto;
}
.home-skeleton .banner-dot .dot {
  height: 8px;
  border-radius: 10px;
  width: 30px;
  margin: auto;
  margin-top: 8px;
}

.home-skeleton .head .title {
  height: 16px;
  border-radius: 16px;
  margin-left: 8px;
  margin-right: 8px;
  width: 100px;
}
.home-skeleton .head .share {
  height: 10px;
  border-radius: 16px;
  margin-left: 8px;
  margin-right: 8px;
  width: 60px;
}
.home-skeleton .list .card {
  margin-top: 10px;
  height: 100px;
  border-radius: 16px;
}

.home-skeleton .score {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 40px;
}
.home-skeleton .score .box {
  margin-top: 10px;
  height: 80px;
  border-radius: 16px;
  width: 28%;
}
