@font-face {
  font-family: "NewSansArabic";
  src: url("./assets/fonts/NeoSansArabic.ttf");
  font-weight: normal;
}


@font-face {
  font-family: "NewSansArabicBold";
  src: url("./assets/fonts/NeoSansArabicBold.ttf");
  font-weight: bold;
}

* {
  font-family: "NewSansArabic";
  margin: 0;
  padding: 0;
  user-select: none;

}

input,
textarea {
  user-select: auto !important;
}

body {
  background-color: #fff;
  direction: rtl;
}

.app-container {
  padding: 0px 16px;
  margin: auto;
  max-width: 600px;
}

.app-flex-space {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button {
  border: none;
  outline: none;
  padding: 6px 10px;
  border-radius: 4px;
}

.touchable {
  padding: 0;
  margin: 0;
  transition: 0.1s;
}

.touchable:active {
  opacity: 0.6;
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.loader {
  color: #0000ff;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.loader-reverse {
  color: #fff;
  margin: 10px auto;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -2.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 2.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.btn-loading,
.btn-loading:before,
.btn-loading:after {
  border-radius: 50%;
  width: 1.2em;
  height: 1.2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.2s infinite ease-in-out;
  animation: load7 1.2s infinite ease-in-out;
}

.btn-loading {
  color: #0000ff;
  font-size: 10px;
  margin: 30px auto;
  margin-top: -23px;
  position: relative;
  /* text-indent: -9999em; */
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.12s;
  animation-delay: -0.12s;
}

.btn-loading:before,
.btn-loading:after {
  content: "";
  position: absolute;
  top: 0;
}

.btn-loading:before {
  left: -2em;
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.btn-loading:after {
  left: 2em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* Skeleton */

.pure-skeleton {
  width: -webkit-fill-available;
  /* height: 200px; */
  background-image: linear-gradient(90deg, #eee 0px, #e8e7e7 200px, #eee 500px);
  background-size: 600px;
  animation-name: skeleton;
  animation-fill-mode: both;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
}

@keyframes skeleton {
  0% {
    background-position: -100px;
  }

  100% {
    background-position: 100vw;
  }
}



.ptr-element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  color: #aaa;
  z-index: 10;
  text-align: center;
  height: 50px;
  transition: all;
}

.ptr-element .genericon {
  opacity: 0.6;
  font-size: 34px;
  width: auto;
  height: auto;
  transition: all 0.25s ease;
  transform: rotate(90deg);
  margin-top: 5px;
}
.ptr-refresh .ptr-element .genericon {
  transform: rotate(270deg);
}
.ptr-loading .ptr-element .genericon,
.ptr-reset .ptr-element .genericon {
  display: none;
}

.loading {
  display: inline-block;
  text-align: center;
  opacity: 0.4;
  margin: 12px 0 0 5px;
  display: none;
  margin-top: 94px;
}
.ptr-loading .loading {
  display: block;
}

.loading span {
  display: inline-block;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  transform: scale(0.3);
  border-radius: 50%;
  animation: ptr-loading 0.4s infinite alternate;
}

.loading-ptr-1 {
  animation-delay: 0 !important;
}

.loading-ptr-2 {
  animation-delay: 0.2s !important;
}

.loading-ptr-3 {
  animation-delay: 0.4s !important;
}

@keyframes ptr-loading {
  0% {
    transform: translateY(0) scale(0.3);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    background-color: #0000ff;
    opacity: 1;
  }
}

.ptr-loading .refresh-view,
.ptr-reset .refresh-view,
.ptr-loading .ptr-element,
.ptr-reset .ptr-element {
  transition: all 0.25s ease;
}

.ptr-reset .refresh-view {
  transform: translate3d(0, 0, 0);
}



.ptr-loading .refresh-view {
  transform: translate3d(0, 30px, 0);
}

body:not(.ptr-loading) .ptr-element {
  transform: translate3d(0, -50px, 0);
}
