.empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty svg {
  margin-bottom: 20px;
  width: 100px;
  margin-top: -140px;
}
.empty p.title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000;
}
.empty p {
  color: #666;
  font-size: 14px;
  max-width: 220px;
  text-align: center;
  line-height: 1.3;
}
