.ui-label {
  border-radius: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: center;
  color: #8d898a;
  background: #f6f6f6;
  border: 1px solid #f0f0f0;

}

.ui-label p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;
}

.ui-label-success {
  background: #e7fff0;
  border: 1px solid #cbf6dc;
  color: #01b348;
}

.ui-label-warning {
  background: #fff8e8;
  border: 1px solid #ffdc84;
  color: #f0ad01;
}


.ui-label-warning p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #f0ad01 !important;
}

.ui-label-success p{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #01b348 !important;
}
