.info-modal .header{
   margin-top: 16px;
   color: #000 !important;
}
.info-modal .header b{
   font-size: 20px;
}

.info-modal .body ul{
   margin: 12px;
}
.info-modal .body ul li{
  margin-bottom: 16px;
  line-height: 1.5;
  font-size: 14px;
}

.info-modal .body ul li:last-child{
margin: 0;
}