.banner-card {
  width: 100%;
  border-radius: 16px;
  border: 1px solid #f0f0f0;
  background-color: #f6f6f6;
  display: contents;
  min-height: 150px;
  overflow: hidden;
  transition: 0.2s;
}

.banner-card:active {
  opacity: 0.4;
}

.banner-card img {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}
