.home-list {
}

.home-list .list-header {
  background-color: #f0f0ff;
  height: 136px;
  border-radius: 16px;
  margin: 0 -8px;
  padding: 8px;
}
.home-list .list-header .title {
  align-items: flex-start;
}
.home-list .list-header .title .label {
  padding: 8px;
  text-align: right;
}
.home-list .list-header b {
  color: #000;
  font-size: 18px;
}
.home-list .list-header p {
  color: #666;
  font-size: 14px;
  margin-top: 4px;
}

.home-list .list {
  margin-top: -75px;
}

.home-list .home-card {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  padding: 8px;
  margin-bottom: 12px;
}

.home-list .home-card .body {
  display: flex;
  padding: 16px 0;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.home-list .home-card .body .team {
  text-align: center;
  flex: 1.2;
  position: relative;
}

.home-list .home-card .body .info {
  text-align: center;
  width: 25%;
  flex: 1;
}
.home-list .home-card .body .info b {
  font-size: 16px;
}
.home-list .home-card .body .info p {
  font-size: 16px;
  margin-top: 4px;
  color: #666;
}
.home-list .home-card .body .team p {
  font-size: 16px;
  margin-top: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
          line-clamp: 1;
  -webkit-box-orient: vertical;

}
.home-list .home-card .body .team img {
  width: 56px;
  height: 56px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  object-fit: cover;
}

.home-list .home-card .body .teamRow {
  display: flex;
  align-items: center;
}

.home-list .home-card .body .teamRow img {
  width: 40px;
  height: 40px;
  position: inherit;
}

.home-list .home-card .body .teamA {
  flex-direction: row-reverse;

}

.home-list .home-card .body .teamA img{
  margin-right: 8px;
}
.home-list .home-card .body .teamB img{
  margin-left: 8px;
  position: sticky;
}

.home-list .home-card .body .teamA svg{
  position: absolute;
  top: -11.5px;
  left: 10px;
  z-index: 0;
}
.home-list .home-card .body .teamB svg{
  position: absolute;
  top: -11.5px;
  right: 10px;
  z-index: 0;
}

.home-list .home-card .action {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-list .home-card .action p {
  color: #666;
}
.home-list .home-card .action .dot {
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #01dc73;
  box-shadow: 0px 0px 2px #01dc73;
}
